import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    TextField,
    Button,
} from '@mui/material';
import { hoaService } from './ApiClients';
import { HoaSchema, HoaCreateOrUpdateSchema } from './hoa-api-client';
import NavBar from './NavBar';
import './HomePage.css';

type Hoa = HoaSchema;

const HomePage: React.FC = () => {
    const [data, setData] = useState<Hoa[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(false);
    const [selectedHoa, setSelectedHoa] = useState<HoaCreateOrUpdateSchema>({
        name: '',
        description: null,
        address: null,
        contact_phone: null,
        contact_email: null,
        website_url: null,
        is_active: true,
    });

    const handleNewHoa = () => {
        setSelectedId(null);
        setIsNew(true);
        setIsEditing(true);
        setSelectedHoa({
            name: '',
            description: null,
            address: null,
            contact_phone: null,
            contact_email: null,
            website_url: null,
            is_active: true,
        });
    };

    const handleCardClick = (hoa: Hoa) => {
        if (hoa.id !== undefined) {
            setSelectedId(hoa.id);
        } else {
            console.error('HOA does not have an ID');
            // You might want to handle this case differently
        }
        setIsNew(false);
        setSelectedHoa(hoa);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        const nameMapping: { [key: string]: keyof HoaCreateOrUpdateSchema } = {
            phone: 'contact_phone',
            email: 'contact_email',
            website: 'website_url',
            name: 'name',
            description: 'description',
            address: 'address',
        };

        const stateKey = nameMapping[name] || (name as keyof Hoa);

        setSelectedHoa((prevState) => ({ ...prevState, [stateKey]: value }));
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
        setIsNew(!isNew);
    };

    const saveDetails = async () => {
        try {
            if (isNew) {
                const response = await hoaService.createHoa({
                    hoaCreateOrUpdateSchema: selectedHoa,
                });
                const newHoa = response.data;
                setData([...data, newHoa]);
                if (newHoa.id !== undefined) {
                    setSelectedId(newHoa.id);
                }
                setSelectedHoa(newHoa);
            } else if (selectedId) {
                const response = await hoaService.updateHoa({
                    hoaId: selectedId,
                    hoaCreateOrUpdateSchema: selectedHoa,
                });
                const updatedHoa = response.data;
                setData(
                    data.map((hoa) =>
                        hoa.id === selectedId ? updatedHoa : hoa
                    )
                );
                setSelectedHoa(updatedHoa);
            }
            setIsNew(false);
            setIsEditing(false);
        } catch (error) {
            console.error('Failed to save HOA:', error);
            setError('Failed to save HOA. Please try again.');
        }
    };

    const fetchHoas = async () => {
        setIsLoading(true);
        try {
            const response = await hoaService.getAllActiveHoas();
            setData(response.data);
        } catch (error) {
            console.error('Failed to fetch HOAs:', error);
            setError('Failed to fetch HOAs. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchHoas();
    }, []);

    const inputStyle = (isEditing: boolean) => ({
        style: {
            backgroundColor: 'white',
            color: isEditing ? 'black' : 'rgba(0, 0, 0, 0.7)',
        },
    });

    const textFieldSx = (isEditing: boolean) => ({
        '.MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: isEditing ? 'primary.main' : 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover fieldset': {
                borderColor: isEditing ? 'primary.main' : 'rgba(0, 0, 0, 0.23)',
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
            },
        },
    });

    return (
        <Box
            sx={{ flexGrow: 1, display: 'flex', height: 'calc(100vh - 100px)' }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <NavBar></NavBar>
                </Grid>
                <Grid item xs={3}>
                    <Box
                        sx={{
                            height: 'calc(100vh - 100px)',
                            backgroundColor: 'gray',

                            margin: 2,
                            left: 10,

                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0,0,0,.1)',
                                outline: '1px solid slategrey',
                            },
                        }}
                    >
                        <Typography align="center" variant="h6">
                            My HOAs
                        </Typography>
                        {isLoading ? (
                            <Typography>Loading...</Typography>
                        ) : error ? (
                            <Typography color="error">{error}</Typography>
                        ) : (
                            data.map((item, index) => (
                                <Card
                                    key={index}
                                    sx={{
                                        margin: 2,
                                        boxShadow: 3,
                                        backgroundColor:
                                            selectedId === item.id
                                                ? '#b2dfdb'
                                                : 'white',
                                    }}
                                    onClick={() => handleCardClick(item)}
                                >
                                    <CardContent>
                                        <Typography>{item.name}</Typography>
                                    </CardContent>
                                </Card>
                            ))
                        )}
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box
                        sx={{
                            height: 'calc(100vh - 100px)',
                            p: 2,
                            backgroundColor: 'white',
                            margin: 2,
                            right: 10,
                            border: '2px solid grey',
                        }}
                    >
                        {selectedId ? (
                            <Typography align="center" variant="h6">
                                HOA Details
                            </Typography>
                        ) : (
                            <Typography variant="h6">Select an HOA</Typography>
                        )}

                        <TextField
                            fullWidth
                            label="Name"
                            variant="outlined"
                            margin="normal"
                            name="name"
                            value={selectedHoa.name}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            InputProps={inputStyle(isEditing)}
                            sx={textFieldSx(isEditing)}
                        />
                        <TextField
                            fullWidth
                            label="Address"
                            variant="outlined"
                            margin="normal"
                            name="address"
                            value={selectedHoa.address}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            InputProps={inputStyle(isEditing)}
                            sx={textFieldSx(isEditing)}
                        />
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    variant="outlined"
                                    margin="normal"
                                    name="phone"
                                    value={selectedHoa.contact_phone}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    InputProps={inputStyle(isEditing)}
                                    sx={textFieldSx(isEditing)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    margin="normal"
                                    name="email"
                                    value={selectedHoa.contact_email}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    InputProps={inputStyle(isEditing)}
                                    sx={textFieldSx(isEditing)}
                                />
                            </Grid>
                        </Grid>

                        <TextField
                            fullWidth
                            label="Website"
                            variant="outlined"
                            margin="normal"
                            name="website"
                            value={selectedHoa.website_url}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            InputProps={inputStyle(isEditing)}
                            sx={textFieldSx(isEditing)}
                        />

                        <TextField
                            fullWidth
                            label="Description"
                            variant="outlined"
                            margin="normal"
                            name="description"
                            value={selectedHoa.description}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            multiline
                            rows={2}
                            InputProps={inputStyle(isEditing)}
                            sx={textFieldSx(isEditing)}
                        />

                        <Box
                            sx={{
                                mt: 1,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <Button
                                    disabled={isNew}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNewHoa}
                                    sx={{ m: 2 }}
                                >
                                    Create New HOA
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={toggleEdit}
                                    sx={{ mr: 1 }}
                                >
                                    {isEditing ? 'Cancel' : 'Edit'}
                                </Button>
                                {isEditing && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={saveDetails}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Box>
                            <Box>
                                <Button variant="contained" color="secondary">
                                    Manage Community
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HomePage;
