/* tslint:disable */
/* eslint-disable */
/**
 * Auth
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface BodyVerifyToken
 */
export interface BodyVerifyToken {
    /**
     * 
     * @type {string}
     * @memberof BodyVerifyToken
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CreateUserSchema
 */
export interface CreateUserSchema {
    /**
     * 
     * @type {string}
     * @memberof CreateUserSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserSchema
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserSchema
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface UserSchema
 */
export interface UserSchema {
    /**
     * 
     * @type {string}
     * @memberof UserSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserSchema
     */
    'full_name': string;
    /**
     * 
     * @type {number}
     * @memberof UserSchema
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserSchema
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Processes request to retrieve user profile by id
         * @summary Profile
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProfile', 'id', id)
            const localVarPath = `/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSvc: async (svc: string, path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'svc' is not null or undefined
            assertParamExists('getSvc', 'svc', svc)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getSvc', 'path', path)
            const localVarPath = `/api/{svc}/{path}`
                .replace(`{${"svc"}}`, encodeURIComponent(String(svc)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes user\'s authentication and returns a token on successful authentication.  request body:  - username: Unique identifier for a user e.g email,             phone number, name  - password:
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('login', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('login', 'password', password)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes request to register user account.
         * @summary Signup
         * @param {CreateUserSchema} createUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (createUserSchema: CreateUserSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserSchema' is not null or undefined
            assertParamExists('signup', 'createUserSchema', createUserSchema)
            const localVarPath = `/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testConnections: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test-connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifies the validity of a token.  request body: - token: JWT token to verify
         * @summary Verify Token
         * @param {BodyVerifyToken} bodyVerifyToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken: async (bodyVerifyToken: BodyVerifyToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyVerifyToken' is not null or undefined
            assertParamExists('verifyToken', 'bodyVerifyToken', bodyVerifyToken)
            const localVarPath = `/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyVerifyToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Processes request to retrieve user profile by id
         * @summary Profile
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRootInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRootInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getRootInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {string} svc 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSvc(svc: string, path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSvc(svc, path, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getSvc']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Processes user\'s authentication and returns a token on successful authentication.  request body:  - username: Unique identifier for a user e.g email,             phone number, name  - password:
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(username, password, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootLivenessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootLivenessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.rootLivenessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootReadinessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootReadinessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.rootReadinessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Processes request to register user account.
         * @summary Signup
         * @param {CreateUserSchema} createUserSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(createUserSchema: CreateUserSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(createUserSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.signup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Test Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testConnections(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testConnections(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.testConnections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Verifies the validity of a token.  request body: - token: JWT token to verify
         * @summary Verify Token
         * @param {BodyVerifyToken} bodyVerifyToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyToken(bodyVerifyToken: BodyVerifyToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyToken(bodyVerifyToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.verifyToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Processes request to retrieve user profile by id
         * @summary Profile
         * @param {AuthApiGetProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(requestParameters: AuthApiGetProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.getProfile(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootInfo(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getRootInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
         * @summary Get Svc
         * @param {AuthApiGetSvcRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSvc(requestParameters: AuthApiGetSvcRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getSvc(requestParameters.svc, requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes user\'s authentication and returns a token on successful authentication.  request body:  - username: Unique identifier for a user e.g email,             phone number, name  - password:
         * @summary Login
         * @param {AuthApiLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParameters: AuthApiLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.login(requestParameters.username, requestParameters.password, requestParameters.grantType, requestParameters.scope, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootLivenessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootReadinessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Processes request to register user account.
         * @summary Signup
         * @param {AuthApiSignupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(requestParameters: AuthApiSignupRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSchema> {
            return localVarFp.signup(requestParameters.createUserSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testConnections(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.testConnections(options).then((request) => request(axios, basePath));
        },
        /**
         * Verifies the validity of a token.  request body: - token: JWT token to verify
         * @summary Verify Token
         * @param {AuthApiVerifyTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken(requestParameters: AuthApiVerifyTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.verifyToken(requestParameters.bodyVerifyToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProfile operation in AuthApi.
 * @export
 * @interface AuthApiGetProfileRequest
 */
export interface AuthApiGetProfileRequest {
    /**
     * 
     * @type {number}
     * @memberof AuthApiGetProfile
     */
    readonly id: number
}

/**
 * Request parameters for getSvc operation in AuthApi.
 * @export
 * @interface AuthApiGetSvcRequest
 */
export interface AuthApiGetSvcRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiGetSvc
     */
    readonly svc: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiGetSvc
     */
    readonly path: string
}

/**
 * Request parameters for login operation in AuthApi.
 * @export
 * @interface AuthApiLoginRequest
 */
export interface AuthApiLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly username: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly password: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly grantType?: string | null

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly scope?: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly clientId?: string | null

    /**
     * 
     * @type {string}
     * @memberof AuthApiLogin
     */
    readonly clientSecret?: string | null
}

/**
 * Request parameters for signup operation in AuthApi.
 * @export
 * @interface AuthApiSignupRequest
 */
export interface AuthApiSignupRequest {
    /**
     * 
     * @type {CreateUserSchema}
     * @memberof AuthApiSignup
     */
    readonly createUserSchema: CreateUserSchema
}

/**
 * Request parameters for verifyToken operation in AuthApi.
 * @export
 * @interface AuthApiVerifyTokenRequest
 */
export interface AuthApiVerifyTokenRequest {
    /**
     * 
     * @type {BodyVerifyToken}
     * @memberof AuthApiVerifyToken
     */
    readonly bodyVerifyToken: BodyVerifyToken
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Processes request to retrieve user profile by id
     * @summary Profile
     * @param {AuthApiGetProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getProfile(requestParameters: AuthApiGetProfileRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getProfile(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getRootInfo(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getRootInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pass through, verify the token, and return the response  :param svc: Service route :param path: Path to request :param token: JWT token
     * @summary Get Svc
     * @param {AuthApiGetSvcRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getSvc(requestParameters: AuthApiGetSvcRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getSvc(requestParameters.svc, requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes user\'s authentication and returns a token on successful authentication.  request body:  - username: Unique identifier for a user e.g email,             phone number, name  - password:
     * @summary Login
     * @param {AuthApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(requestParameters: AuthApiLoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(requestParameters.username, requestParameters.password, requestParameters.grantType, requestParameters.scope, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public rootLivenessGet(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).rootLivenessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public rootReadinessGet(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).rootReadinessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes request to register user account.
     * @summary Signup
     * @param {AuthApiSignupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signup(requestParameters: AuthApiSignupRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).signup(requestParameters.createUserSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public testConnections(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).testConnections(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifies the validity of a token.  request body: - token: JWT token to verify
     * @summary Verify Token
     * @param {AuthApiVerifyTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyToken(requestParameters: AuthApiVerifyTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).verifyToken(requestParameters.bodyVerifyToken, options).then((request) => request(this.axios, this.basePath));
    }
}



