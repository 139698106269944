import { AuthApi, Configuration } from './auth-api-client';
import { HoaApi, Configuration as HoaConfiguration } from './hoa-api-client';
import axios, { AxiosInstance } from 'axios';
import { getToken, verifyToken } from './TokenUtils';

const AUTH_SVC_BASE_URL = window._env_.REACT_APP_AUTH_SVC;
const HOA_SVC_BASE_URL = window._env_.REACT_APP_HOA_SVC;

// Initialize the API client
const apiConfig = new Configuration({
    basePath: AUTH_SVC_BASE_URL,
});

export const authService = new AuthApi(apiConfig);

const hoaConfig = new HoaConfiguration({
    basePath: HOA_SVC_BASE_URL,
});

const axiosInstance: AxiosInstance = axios.create({
    baseURL: HOA_SVC_BASE_URL,
});

axiosInstance.interceptors.request.use(
    async (config) => {
        console.log('in axios instance...');
        const token = getToken();
        if (token) {
            const isValid = await verifyToken(token);
            if (isValid) {
                config.headers['Authorization'] = `Bearer ${token}`;
                console.log(config.headers);
            } else {
                console.error('Invalid token');
                // should never actually reach this. verifyToken should fail
                // and useEffect in App.ts will redirect to login with invalid token
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const hoaService = new HoaApi(hoaConfig);
