/* tslint:disable */
/* eslint-disable */
/**
 * Hoa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HoaCreateOrUpdateSchema
 */
export interface HoaCreateOrUpdateSchema {
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'contact_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'contact_phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'website_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaCreateOrUpdateSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HoaCreateOrUpdateSchema
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface HoaSchema
 */
export interface HoaSchema {
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'contact_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'contact_phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'website_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HoaSchema
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HoaSchema
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof HoaSchema
     */
    'date_updated'?: string | null;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootLivenessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootLivenessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootLivenessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootReadinessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootReadinessGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.rootReadinessGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootLivenessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootLivenessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootReadinessGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.rootReadinessGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootLivenessGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootLivenessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootReadinessGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootReadinessGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HoaApi - axios parameter creator
 * @export
 */
export const HoaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Hoa
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoa: async (hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('createHoa', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoa/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoa: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('deleteHoa', 'hoaId', hoaId)
            const localVarPath = `/hoa/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Active Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveHoas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hoas/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoaById: async (hoaId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('getHoaById', 'hoaId', hoaId)
            const localVarPath = `/hoa/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Hoa
         * @param {number} hoaId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoa: async (hoaId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hoaId' is not null or undefined
            assertParamExists('updateHoa', 'hoaId', hoaId)
            // verify required parameter 'hoaCreateOrUpdateSchema' is not null or undefined
            assertParamExists('updateHoa', 'hoaCreateOrUpdateSchema', hoaCreateOrUpdateSchema)
            const localVarPath = `/hoa/{hoa_id}`
                .replace(`{${"hoa_id"}}`, encodeURIComponent(String(hoaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hoaCreateOrUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HoaApi - functional programming interface
 * @export
 */
export const HoaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HoaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Hoa
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHoa(hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHoa(hoaCreateOrUpdateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.createHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHoa(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHoa(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.deleteHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Active Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllActiveHoas(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HoaSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllActiveHoas(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getAllActiveHoas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Hoa
         * @param {number} hoaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHoaById(hoaId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHoaById(hoaId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getHoaById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRootInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRootInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.getRootInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Hoa
         * @param {number} hoaId 
         * @param {HoaCreateOrUpdateSchema} hoaCreateOrUpdateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHoa(hoaId: number, hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoaSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHoa(hoaId, hoaCreateOrUpdateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HoaApi.updateHoa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HoaApi - factory interface
 * @export
 */
export const HoaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HoaApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Hoa
         * @param {HoaApiCreateHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoa(requestParameters: HoaApiCreateHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.createHoa(requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Hoa
         * @param {HoaApiDeleteHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoa(requestParameters: HoaApiDeleteHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.deleteHoa(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Active Hoas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveHoas(options?: RawAxiosRequestConfig): AxiosPromise<Array<HoaSchema>> {
            return localVarFp.getAllActiveHoas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Hoa
         * @param {HoaApiGetHoaByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoaById(requestParameters: HoaApiGetHoaByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.getHoaById(requestParameters.hoaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootInfo(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getRootInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Hoa
         * @param {HoaApiUpdateHoaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoa(requestParameters: HoaApiUpdateHoaRequest, options?: RawAxiosRequestConfig): AxiosPromise<HoaSchema> {
            return localVarFp.updateHoa(requestParameters.hoaId, requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createHoa operation in HoaApi.
 * @export
 * @interface HoaApiCreateHoaRequest
 */
export interface HoaApiCreateHoaRequest {
    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiCreateHoa
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema
}

/**
 * Request parameters for deleteHoa operation in HoaApi.
 * @export
 * @interface HoaApiDeleteHoaRequest
 */
export interface HoaApiDeleteHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiDeleteHoa
     */
    readonly hoaId: number
}

/**
 * Request parameters for getHoaById operation in HoaApi.
 * @export
 * @interface HoaApiGetHoaByIdRequest
 */
export interface HoaApiGetHoaByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiGetHoaById
     */
    readonly hoaId: number
}

/**
 * Request parameters for updateHoa operation in HoaApi.
 * @export
 * @interface HoaApiUpdateHoaRequest
 */
export interface HoaApiUpdateHoaRequest {
    /**
     * 
     * @type {number}
     * @memberof HoaApiUpdateHoa
     */
    readonly hoaId: number

    /**
     * 
     * @type {HoaCreateOrUpdateSchema}
     * @memberof HoaApiUpdateHoa
     */
    readonly hoaCreateOrUpdateSchema: HoaCreateOrUpdateSchema
}

/**
 * HoaApi - object-oriented interface
 * @export
 * @class HoaApi
 * @extends {BaseAPI}
 */
export class HoaApi extends BaseAPI {
    /**
     * 
     * @summary Create Hoa
     * @param {HoaApiCreateHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public createHoa(requestParameters: HoaApiCreateHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).createHoa(requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Hoa
     * @param {HoaApiDeleteHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public deleteHoa(requestParameters: HoaApiDeleteHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).deleteHoa(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Active Hoas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getAllActiveHoas(options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getAllActiveHoas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Hoa
     * @param {HoaApiGetHoaByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getHoaById(requestParameters: HoaApiGetHoaByIdRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getHoaById(requestParameters.hoaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public getRootInfo(options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).getRootInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Hoa
     * @param {HoaApiUpdateHoaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HoaApi
     */
    public updateHoa(requestParameters: HoaApiUpdateHoaRequest, options?: RawAxiosRequestConfig) {
        return HoaApiFp(this.configuration).updateHoa(requestParameters.hoaId, requestParameters.hoaCreateOrUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



